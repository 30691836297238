.blur {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(0.25rem);
  z-index: 5;
}

.container {
  position: fixed;
  inset: 0;
  z-index: 5;
}

.modal {
  z-index: 5;
  opacity: 1;
  position: relative;
  max-width: 64.4rem;
  margin: 0 auto;
  margin-top: 10rem;
  background-color: var(--color-lightblue);
  border-radius: var(--border-radius);
  padding: 1.6rem;
}

.closeButton {
  min-height: 4.8rem;
  min-width: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background: none;
  cursor: pointer;
}
