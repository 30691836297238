:root {
  --color-blue: #0064a7;
  --color-brightblue: #2fb4e9;
  --color-lightblue: #d5f0fb;
  --color-orange: #e16c4c;
  --color-white: #fff;
  --color-black: #000;
  --color-grey: #d9d9d9;
  --color-darkblue-5: #f3f4f6;
  --color-darkblue-20: #9aa1b3;
  --color-darkblue-80: #005085;
  --color-darkblue-100: #18254e;
  --color-red: #af0606;
  --color-lightred: #fbc6c6;
  --color-green: #155724;
  --color-lightgreen: #d4edda;
  --line-height-s: 1;
  --line-height-m: 1.2;
  --line-height-l: 1.5;
  --line-height-xl: 2;
  --font-size-xxs: 1.3rem;
  --font-size-xs: 1.4rem;
  --font-size-s: 1.6rem;
  --font-size-m: 1.8rem;
  --font-size-l: 2rem;
  --font-size-xl: 2.4rem;
  --font-size-xxl: 2.8rem;
  --font-size-xxxl: 3.2rem;
  --font-size-xxxxl: 4rem;
  --font-size-xxxxxl: 6.4rem;
  --font-size-icon-l: 8rem;
  --font-weight-thin: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;
  --border-radius-s: 0.2rem;
  --border-radius: 0.5rem;
  --border-radius-l: 0.8rem;
  --border-radius-round: 50%;
  --default-font: "Helvetica Neue";
  --title-font: "Roboto Flex";
}

/* stylelint-disable-next-line meowtec/no-px */
@custom-media --viewport-m (min-width: 768px);

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgb(255 255 255 / 0%);
  -moz-osx-font-smoothing: grayscale;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  font-size: 62.5%; /* 1rem = 10px */
  width: 100%;
}

body {
  /* Default font */
  font-family: var(--default-font);
  color: var(--color-darkblue-100);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-s);
  line-height: var(--line-height-l);
  letter-spacing: 0.01em;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1 {
  font-family: var(--title-font);
}

h1,
h2,
h3,
h4 {
  font-style: normal;
  font-weight: var(--font-weight-medium);
}

h1,
h2 {
  font-size: var(--font-size-xxxxl);
  line-height: var(--line-height-m);
  font-stretch: 118;
}

h3 {
  font-size: var(--font-size-l);
  line-height: var(--line-height-m);
}

p {
  font-size: var(--font-size-s);
  line-height: var(--line-height-l);
}

a {
  color: var(--color-blue);
  text-decoration: none;
}
